import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

export const useMedia = function () {
  // https://tailwindcss.com/docs/screens => {sm: 640px, md: 768px, lg: 1024px, xl: 1280px}
  const breakpoints = useBreakpoints(breakpointsTailwind);

  const isSmallerThanLaptop = breakpoints.smallerOrEqual("lg");
  const isMobile = breakpoints.smallerOrEqual("sm");

  return {
    isSmallerThanLaptop,
    isMobile,
  };
};
